<template>
  <b-modal
    v-model="modalOpen"
    no-fade ref="create-client"
    :no-close-on-backdrop="true"
    hide-footer hide-header hide-backdrop
    size="xl"
  >
    <div class="row">
      <div class="col-md-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-10">
                <h4 class="card-title mb-4">Agregar Cliente</h4>
              </div>
              <div class="col-2">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="reset">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
            <form class="forms-sample">
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="type_id">Tipo</label>
                  <select v-model="form.type_id" name="type_id" id="type_id" class="form-control">
                    <option value="1">Persona</option>
                    <option value="2">Compañia</option>
                  </select>
                  <div v-if="errors.hasOwnProperty('type_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.type_id[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6" v-if="form.type_id == 1">
                  <label for="first_name">Nombre</label>
                  <input v-model="form.first_name" type="text" class="form-control" id="first_name" placeholder="Nombre">
                  <div v-if="errors.hasOwnProperty('first_name')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.first_name[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6" v-if="form.type_id == 1">
                  <label for="last_name">Apellido</label>
                  <input v-model="form.last_name" type="text" class="form-control" id="last_name" placeholder="Apellido">
                  <div v-if="errors.hasOwnProperty('last_name')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.last_name[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6" v-if="form.type_id == 1">
                  <label for="birth">Fecha Nacimiento</label>
                  <datetime v-model="form.birth" :config="dateConfig" id="birth" class="form-control"></datetime>
                  <div v-if="errors.hasOwnProperty('birth')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.birth[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6" v-if="form.type_id == 2">
                  <label for="commercial_name">Nombre Comercial</label>
                  <input v-model="form.commercial_name" type="text" class="form-control" id="commercial_name" placeholder="Nombre Comercial">
                  <div v-if="errors.hasOwnProperty('commercial_name')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.commercial_name[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label for="identification">Cédula / RNC</label>
                  <input v-model="form.identification" type="text" class="form-control" id="identification" placeholder="Cédula / RNC">
                  <div v-if="errors.hasOwnProperty('identification')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.identification[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="address">Dirección</label>
                <textarea v-model="form.address" name="address" id="address" cols="30" rows="5" class="form-control"></textarea>
                <div v-if="errors.hasOwnProperty('address')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.address[0]}}</small>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6">
                  <div class="row justify-content-between align-items-center">
                    <div class="col-auto"><label for="email">Email</label></div>
                    <div class="col-auto">
                      <button type="button" class="btn btn-sm btn-link p-0 mb-2" @click.prevent="addEmailContact">
                        <i class="mdi mdi-plus"></i>
                      </button>
                    </div>
                  </div>
                  <input v-model="form.email" type="text" class="form-control" id="email" placeholder="Correo electrónico">
                  <div v-if="errors.hasOwnProperty('email')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.email[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row" v-for="(contact, index) in form.email_contacts" :key="index">
                <div class="form-group col-lg-6">
                  <label :for="'email_' + index">Email</label>
                  <input v-model="contact.email" type="text" class="form-control" :id="'email_' + index" placeholder="Correo electrónico">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-lg-6">
                  <label for="phone">Teléfono</label>
                  <input v-model="form.phone" type="text" class="form-control" id="phone" placeholder="Teléfono">
                  <div v-if="errors.hasOwnProperty('phone')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.phone[0]}}</small>
                  </div>
                </div>
                <div class="form-group col-lg-6">
                  <label for="phone">Móvil</label>
                  <input v-model="form.mobile" type="text" class="form-control" id="mobile" placeholder="Móvil">
                  <div v-if="errors.hasOwnProperty('mobile')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.mobile[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-auto">
                  <button @click.prevent="createClient" class="btn btn-gradient-info btn-md">Guardar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js'
import { Spanish } from 'flatpickr/dist/l10n/es.js'

export default {
  name: 'CreateClient',
  data () {
    return {
      form: {
        first_name: null,
        last_name: null,
        commercial_name: null,
        identification: null,
        address: null,
        birth: null,
        email: null,
        email_contacts: [],
        phone: null,
        mobile: null,
        type_id: 1
      },
      dateConfig: {
        altInput: true,
        enableTime: false,
        plugins: [new ConfirmDatePlugin({
          confirmIcon: '<i class="fa fa-check"></i>',
          confirmText: 'OK ',
          showAlways: false
        })],
        altFormat: 'F j, Y',
        dateFormat: 'Y-m-d',
        time_24hr: false,
        defaultHour: 9,
        defaultMinute: 0,
        locale: Spanish
      },
      errors: {}
    }
  },
  methods: {
    ...mapActions({
      create: 'Client/create',
      getClientsDropdown: 'Client/getClients'
    }),
    ...mapMutations('Client', ['TOGGLE_MODAL_CREATE']),
    createClient () {
      if (parseInt(this.form.type_id) === 2) {
        delete this.form.first_name
        delete this.form.last_name
      } else {
        delete this.form.commercial_name
      }
      this.create(this.form)
        .then(() => {
          this.reset()
          this.getClientsDropdown()
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          if (!error.response.data.errors) {
            this.$swal(error.response.data.message, '', 'error')
          }
        })
    },
    reset () {
      this.form.first_name = null
      this.form.last_name = null
      this.form.commercial_name = null
      this.form.identification = null
      this.form.address = null
      this.form.birth = null
      this.form.email = null
      this.form.email_contacts = []
      this.form.phone = null
      this.form.mobile = null
      this.form.type_id = 1
      this.errors = {}
      this.$events.fire('refresh-table')
      this.TOGGLE_MODAL_CREATE(false)
    },
    addEmailContact () {
      this.form.email_contacts.push({ email: '' })
    }
  },
  computed: {
    ...mapGetters('Client', ['modalCreate']),
    modalOpen () {
      return this.modalCreate
    }
  }
}
</script>
