<template>
  <b-modal v-model="CLIENT_TOGGLE_MODAL_CREATE" ref="attachment-uploader" no-fade size="xl" :no-close-on-backdrop="true" hide-footer hide-header hide-backdrop>
    <div class="row">
      <div class="col-md-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-10">
                <h4 class="card-title mb-4">Adjuntar Archivos</h4>
              </div>
              <div class="col-2">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="reset">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
            <form class="forms-sample">
              <div class="form-group">
                <label for="name">Nombre</label>
                <input type="text" v-model="form.name" class="form-control" id="name" />
                <div v-if="errors.hasOwnProperty('name')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.name[0]}}</small>
                </div>
              </div>
              <div class="form-group">
                <label for="due_date">Fecha de Vencimiento</label>
                <datetime v-model="form.due_date" :config="dateConfig" id="due_date" class="form-control"></datetime>
                <div v-if="errors.hasOwnProperty('due_date')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.due_date[0]}}</small>
                </div>
              </div>
              <div class="form-group" v-if="form.name && form.due_date">
                <label>Archivos</label>
                <vue-dropzone ref="dropzone" id="dropzone" :options="dropzone" v-on:vdropzone-complete="completedEvent" v-on:vdropzone-sending="sendingEvent" v-on:vdropzone-files-added="filesAdded"  v-on:vdropzone-canceled="uploadCancelled"></vue-dropzone>
              </div>
              <div class="row justify-content-between">
                <div class="col-12 col-md-auto order-2 order-md-1">
                  <button @click.prevent="reset" class="btn btn-outline-dark btn-md btn-block">Cancelar</button>
                </div>
                <div class="col-12 col-md-auto order-1 order-md-2 mb-2 mb-md-0" v-if="attachmentQueueComplete">
                  <button @click.prevent="reset" class="btn btn-info btn-md btn-block">Continuar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import vueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import { mapMutations, mapGetters } from 'vuex'
import Vue from 'vue'
import VueCookie from 'vue-cookie'

Vue.use(VueCookie)

const getTemplate = () => `<div class="dz-preview dz-file-preview">
<div class="dz-image">
  <div data-dz-thumbnail-bg></div>
</div>
<div class="dz-details">
  <div class="dz-filename"><span data-dz-name></span></div>
</div>
<div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
<div class="dz-error-message"><span data-dz-errormessage></span></div>
<div class="dz-success-mark"><i class="fa fa-check"></i></div>
<div class="dz-error-mark"><i class="fa fa-close"></i></div>
</div>`

export default {
  name: 'FileUploaderModal',
  props: {
    payload: {
      type: Object,
      required: true
    },
    dateConfig: {
      altInput: true,
      enableTime: false,
      altFormat: 'F j, Y G:i K',
      dateFormat: 'Y-m-d H:i',
      locale: Spanish, // locale for this instance only,
      time_24hr: false
    }
  },
  data: () => {
    return {
      errors: [],
      attachmentQueueComplete: false,
      form: {
        due_date: '',
        name: ''
      },
      dropzone: {
        url: '/api/client_attachments',
        headers: {
          'X-XSRF-TOKEN': Vue.cookie.get('XSRF-TOKEN')
        },
        withCredentials: true,
        maxFilesize: 25, // MB
        maxFiles: 5,
        thumbnailWidth: 120, // px
        thumbnailHeight: 120,
        addRemoveLinks: true,
        dictDefaultMessage: '¡Arrastre los documentos aquí!',
        thumbnail: function (file, dataUrl) {
          if (file.previewElement) {
            file.previewElement.classList.remove('dz-file-preview')
            const images = file.previewElement.querySelectorAll('[data-dz-thumbnail]')
            for (let i = 0; i < images.length; i++) {
              const thumbnailElement = images[i]
              thumbnailElement.alt = file.name
              thumbnailElement.src = dataUrl
            }
            setTimeout(function () { file.previewElement.classList.add('dz-image-preview') }, 1)
          }
        },
        previewTemplate: getTemplate()
      }
    }
  },
  methods: {
    reset () {
      this.form = {
        due_date: '',
        name: ''
      }
      this.attachmentQueueComplete = false
      this.$events.fire('refresh-table')
      this.TOGGLE_MODAL_CREATE()
    },
    filesAdded () {
      this.$refs.dropzone.setOption('url', process.env.VUE_APP_BASE_URL + 'api/client_attachments')
    },
    uploadCancelled () {
      this.$refs.dropzone.setOption('url', process.env.VUE_APP_BASE_URL + 'api/client_attachments')
      window.location.replace('')
    },
    completedEvent () {
      this.attachmentQueueComplete = true
    },
    sendingEvent (file, xhr, formData) {
      formData.append('due_date', this.form.due_date)
      formData.append('name', this.form.name)
      formData.append('client_id', this.payload.id)
      formData.append('last_modified', new Date(file.lastModified).toISOString())
    },
    ...mapMutations({
      TOGGLE_MODAL_CREATE: 'ClientAttachment/TOGGLE_MODAL_CREATE'
    })
  },
  computed: {
    ...mapGetters({
      CLIENT_TOGGLE_MODAL_CREATE: 'ClientAttachment/modalCreate'
    })
  },
  components: {
    vueDropzone
  }
}
</script>
